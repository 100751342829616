/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		The Bayswater
		Date:		27th October 2020
		Author:		Edge Marketing Solutions

===========================================================================*/
/*=========================================================================*/
/* [Global Template Styles] */
body {
  color: #717171;
  font-family: 'Bellota Text', sans-serif;
  font-size: 18px;
}

a {
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  text-decoration: none;
  color: #55caf5;
}

img {
  max-width: 100%;
}

img.img-lightbox {
  cursor: zoom-in;
}

h1 {
  margin-bottom: 2rem;
  color: #5673b5;
  font-size: 60px;
}

h2 {
  margin-bottom: 0rem;
  margin-top: 0rem;
  color: #5673b5;
  font-size: 50px;
}

h3 {
  margin-bottom: 0rem;
  margin-top: 0rem;
  color: #5673b5;
  font-size: 40px;
}

h4 {
  margin-bottom: 0rem;
  margin-top: 0rem;
  color: #5673b5;
  font-size: 30px;
}

h5 {
  margin-bottom: 0rem;
  margin-top: 0rem;
  color: #5673b5;
  font-size: 20px;
}

h6 {
  margin-bottom: 0rem;
  margin-top: 0rem;
  color: #5673b5;
  font-size: 20px;
  font-weight: 700;
}

.btn-primary {
  padding: 0.5rem 2rem;
  background-color: #55caf5;
  border: none;
  border-radius: 30px;
  text-transform: uppercase;
}

.btn-primary:hover {
  background-color: #5673b5;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 30px;
  }
  h4 {
    font-size: 25px;
  }
}

/*=========================================================================*/
/* [Header Template Styles] */
.header {
  padding: 50px 50px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.header .logo img {
  max-width: 285px;
}

.header h1 {
  margin: 100px 0 130px;
  color: white;
  text-align: center;
}

.header .cta .social {
  font-size: 30px;
}

.header .h-phone a {
  color: white;
  font-size: 20px;
}

.header:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.header:after {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-image: url(../images/banner-overlay-wave.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.header-homepage {
  height: 100vh;
  min-height: 700px;
}

.header-homepage .container-fluid {
  z-index: 4000;
}

.header-homepage:before {
  display: none;
}

.swiper-container .swiper-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.swiper-container .swiper-wrapper .swiper-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.swiper-container .swiper-wrapper .swiper-slide-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: bottom center;
}

.swiper-container .swiper-wrapper .swiper-slide-image:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.swiper-container .swiper-wrapper .swiper-text {
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 1000;
  padding: 0 5vw;
  text-align: center;
  color: white;
  font-size: 50px;
}

/* Large desktop */
/* Desktop */
@media (min-width: 1199px) and (max-width: 1572px) {
  .header {
    padding: 50px 30px;
  }
  .swiper-container .swiper-text {
    font-size: 40px !important;
  }
}

/* Desktop */
@media (max-width: 1199px) {
  .header {
    padding: 40px 20px;
  }
  .swiper-container .swiper-text {
    font-size: 40px !important;
  }
}

/* Portrait tablet to landscape and desktop */
@media (max-width: 991px) {
  .header:after {
    height: 50px;
  }
  .swiper-container .swiper-text {
    font-size: 30px !important;
  }
}

/*=========================================================================*/
/* [Navigation Template Styles] */
.navbar {
  margin: 0;
  padding: 0;
}

.navbar .nav-link {
  padding: 0 1rem !important;
  color: white !important;
  font-size: 20px;
  line-height: 20px;
}

.navbar .navbar-toggler {
  background-color: white;
}

.navbar .dropdown-item {
  font-size: 20px;
}

.navbar .dropdown-item.active,
.navbar .dropdown-item:active {
  background-color: #55caf5;
}

/* Large desktop */
/* Desktop */
@media (min-width: 1199px) and (max-width: 1572px) {
  .navbar .nav-link {
    padding: 0 0.7rem !important;
    font-size: 18px;
    line-height: 18px;
  }
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar {
    margin: 30px 0 0 0;
  }
  .navbar .nav-link {
    padding: 0 0.6rem !important;
    font-size: 16px;
    line-height: 16px;
  }
}

/* Portrait tablet to landscape and desktop */
@media (max-width: 991px) {
  .navbar {
    margin: 20px 0;
    justify-content: center;
  }
  .navbar .nav-link {
    padding: 1rem 0.7rem !important;
  }
  .navbar .navbar-nav {
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.9);
    text-align: center;
  }
  .navbar .navbar-nav .dropdown-menu {
    text-align: center;
  }
}

/* Landscape phone to portrait tablet */
/*=========================================================================*/
/* [Page Template Styles] */
.page {
  width: 100%;
  padding: 50px 0;
}

.swiper-container {
  width: 100%;
}

.swiper-container .swiper-slide img {
  width: 100%;
}

.subpage-banner img {
  width: 100%;
}

.gallery-hover {
  position: relative;
}

.gallery-link {
  position: relative;
  width: 100%;
  height: 100%;
}

.hover-caption {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
  display: none;
  overflow: hidden;
}

.hover-caption .caption {
  position: absolute;
  color: white;
  text-align: center;
  padding: 15%;
  top: 50%;
  transform: translateY(-50%);
}

.table h3,
.table h4,
.table h5 {
  margin: 0;
}

.table-bordered {
  border: none;
}

.table-bordered th,
.table-bordered td {
  border-top: none;
  border-left: none;
  border-right: none;
}

.cta-panel {
  width: 100%;
  padding: 80px 80px 100px 80px;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 30px solid white;
  color: white;
  text-align: center;
}

.cta-panel h1,
.cta-panel h2,
.cta-panel h3,
.cta-panel h4,
.cta-panel h5,
.cta-panel h6 {
  margin: 0 0 1rem 0;
  color: white;
}

.cta-panel:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.7);
}

.cta-panel-about:before {
  background-color: rgba(86, 115, 181, 0.6);
}

.cta-panel-about:after {
  content: ' ';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 400px;
  height: 200px;
  z-index: -1;
  background-image: url(../images/icon-waves-white.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.homepage-sections {
  margin: 0;
  padding: 0;
  padding: 0;
}

.homepage-sections .col-lg-6 {
  background-size: cover;
  min-height: 300px;
}

.homepage-sections .content {
  padding: 10%;
}

.feature-nav {
  padding: 0 50px;
  margin-top: -100px;
  z-index: 2000;
  position: relative;
}

.feature-nav .item {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 531px;
  padding: 20px;
  position: relative;
  z-index: 0;
  border-radius: 30px;
}

.feature-nav .item .title {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
  font-size: 60px;
  color: white;
}

.feature-nav .item:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.7);
}

.feature-nav .item:hover:before {
  background-color: rgba(0, 0, 0, 0.3);
}

/* Large desktop */
/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .feature-nav .item .title {
    font-size: 40px;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .cta-panel-about:after {
    display: none;
  }
  .feature-nav {
    margin-top: 0;
  }
  .feature-nav .col-md-6 {
    margin-top: 30px;
  }
  .feature-nav .item {
    min-height: 300px;
  }
  .feature-nav .item .title {
    font-size: 40px;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .cta-panel {
    padding: 30px;
  }
  .cta-panel-about:after {
    display: none;
  }
  .feature-nav {
    margin-top: 0;
    padding: 0 20px;
  }
  .feature-nav .col-md-6 {
    margin-top: 30px;
  }
  .feature-nav .item {
    min-height: 300px;
  }
  .feature-nav .item .title {
    font-size: 40px;
  }
}

/*=========================================================================*/
/* [Footer Template Styles] */
.footer {
  width: 100%;
  padding: 60px 50px;
  position: relative;
  border-bottom: 48px solid #55caf5;
  background-image: url(../images/bg-footer.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  text-align: center;
}

.footer a {
  color: white;
}

.footer a:hover {
  color: #55caf5;
}

.footer .btn-primary:hover {
  color: white;
}

.footer .logo img {
  max-width: 180px;
}

.footer .social {
  font-size: 30px;
  color: #55caf5;
}

.footer .nav {
  justify-content: center;
  margin-bottom: 20px;
}

.footer .btn-secondary {
  padding: 0.5rem 2rem;
  background-color: transparent;
  background-image: none;
  border: 1px solid white;
  border-radius: 30px;
  text-transform: uppercase;
}

.footer:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .footer .nav {
    display: block;
  }
}

/*=========================================================================*/
/* [Table as row override Styles] */
table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

/* col-lg */
@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

/* col-md */
@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

/* col-sm */
@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

/* col-xs */
table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}
