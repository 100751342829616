/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		The Bayswater
		Date:		27th October 2020
		Author:		Edge Marketing Solutions

===========================================================================*/

	$color-grey: rgb(113,113,113);
	$color-blue-dark: rgb(86,115,181);
	$color-blue-light: rgb(85,202,245);
	$color-white: rgb(255,255,255);

/*=========================================================================*/

	/* [Global Template Styles] */

	body
	{
		color: $color-grey;
		font-family: 'Bellota Text', sans-serif;
		font-size: 18px;
	}

	a
	{
		-webkit-transition: all 150ms ease-in-out;
		-moz-transition: all 150ms ease-in-out;
		-ms-transition: all 150ms ease-in-out;
		-o-transition: all 150ms ease-in-out;
		transition: all 150ms ease-in-out;

		text-decoration: none;
		color: $color-blue-light;
	}

	img
	{
		max-width: 100%;
	}

	img.img-lightbox
	{
		cursor: zoom-in;
	}

	h1 {
		margin-bottom: 2rem;

		color: $color-blue-dark;
		font-size: 60px;
	}

	h2 {
		margin-bottom: 0rem;
		margin-top: 0rem;

		color: $color-blue-dark;
		font-size: 50px;
	}

	h3 {
		margin-bottom: 0rem;
		margin-top: 0rem;

		color: $color-blue-dark;
		font-size: 40px;
	}

	h4 {
		margin-bottom: 0rem;
		margin-top: 0rem;

		color: $color-blue-dark;
		font-size: 30px;
	}

	h5 {
		margin-bottom: 0rem;
		margin-top: 0rem;

		color: $color-blue-dark;
		font-size: 20px;
	}

	h6 {
		margin-bottom: 0rem;
		margin-top: 0rem;

		color: $color-blue-dark;
		font-size: 20px;
		font-weight: 700;
	}

	.btn-primary
	{
		padding: 0.5rem 2rem;

		background-color: $color-blue-light;
		border: none;
		border-radius: 30px;

		text-transform: uppercase;
	}

		.btn-primary:hover
		{
			background-color: $color-blue-dark;
		}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		h1 {
			font-size: 50px;
		}

		h2 {
			font-size: 40px;
		}

		h3 {
			font-size: 30px;
		}

		h4 {
			font-size: 25px;
		}
	}

	/*=========================================================================*/

	/* [Header Template Styles] */

	.header
	{
		padding: 50px 50px;
		position: relative;

		background-repeat: no-repeat;
		background-size: cover;

		.logo img
		{
			max-width: 285px;
		}

		h1
		{
			margin: 100px 0 130px;

			color: $color-white;
			text-align: center;
		}

		.cta
		{
			.social
			{
				font-size: 30px;
			}
		}

		.h-phone a
		{
			color: $color-white;
			font-size: 20px;
		}
	}

		.header:before
		{
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			background-color: rgba(0,0,0,0.5);
		}

		.header:after
		{
			content: ' ';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100px;

			background-image: url(../images/banner-overlay-wave.svg);
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}

	.header-homepage
	{
		height: 100vh;
		min-height: 700px;

		.container-fluid{
			z-index: 4000;
		}
	}

		.header-homepage:before
		{
			display: none;
		}

	.swiper-container
	{
		.swiper-wrapper
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;

			.swiper-slide
			{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

			.swiper-slide-image
			{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				background-size: cover;
				background-position: bottom center;
			}

			.swiper-slide-image:before
			{
				content: ' ';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				background-color: rgba(0,0,0,0.5);
			}

			.swiper-text
			{
				position: absolute;
				top: 50%;
				width: 100%;
				z-index: 1000;
				padding: 0 5vw;

				text-align: center;

				color: $color-white;
				font-size: 50px;
			}
		}
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 1199px) and (max-width: 1572px) {
		.header
		{
			padding: 50px 30px;
		}

		.swiper-container
		{
			.swiper-text
			{
				font-size: 40px !important;
			}
		}
	}

	/* Desktop */
	@media (max-width: 1199px) {
		.header
		{
			padding: 40px 20px;
		}

		.swiper-container
		{
			.swiper-text
			{
				font-size: 40px !important;
			}
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media(max-width: 991px) {
		.header:after
		{
			height: 50px;
		}

		.swiper-container
		{
			.swiper-text
			{
				font-size: 30px !important;
			}
		}
	}

	/*=========================================================================*/

	/* [Navigation Template Styles] */

	.navbar
	{
		margin: 0;
		padding: 0;

		.nav-link
		{
			padding: 0 1rem !important;

			color: $color-white !important;
			font-size: 20px;
			line-height: 20px;
		}

		.navbar-toggler
		{
			background-color: $color-white;
		}

		.dropdown-item
		{
			font-size: 20px;
		}

		.dropdown-item.active,
		.dropdown-item:active
		{
			background-color: $color-blue-light;
		}
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 1199px) and (max-width: 1572px) {
		.navbar {
			.nav-link {
				padding: 0 0.7rem !important;
				font-size: 18px;
				line-height: 18px;
			}
		}
	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.navbar {
			margin: 30px 0 0 0;

			.nav-link {
				padding: 0 0.6rem !important;
				font-size: 16px;
				line-height: 16px;
			}
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (max-width: 991px) {
		.navbar {
			margin: 20px 0;
			justify-content: center;

			.nav-link {
				padding: 1rem 0.7rem !important;
			}

			.navbar-nav
			{
				padding: 20px;
				background-color: rgba(0,0,0,0.9);
				text-align: center;

				.dropdown-menu
				{
					text-align: center;
				}
			}
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

	}

	/*=========================================================================*/

	/* [Page Template Styles] */

	.page
	{
		width: 100%;
		padding: 50px 0;
	}

	.swiper-container
	{
		width: 100%;
	}

		.swiper-container .swiper-slide img
		{
			width: 100%;
		}

	.subpage-banner img
	{
		width: 100%;
	}

	.gallery-hover
	{
		position: relative;
	}

	.gallery-link
	{
		position: relative;
		width: 100%;
		height: 100%;
	}

	.hover-caption
	{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.75);
		z-index: 10;
		display: none;
		overflow: hidden;

		.caption
		{
			position: absolute;
			color: white;
			text-align: center;
			padding: 15%;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.table
	{
		h3,
		h4,
		h5
		{
			margin: 0;
		}
	}

	.table-bordered
	{
		border: none;

		th,
		td
		{
			border-top: none;
			border-left: none;
			border-right: none;
		}
	}

	.cta-panel
	{
		width: 100%;
		padding: 80px 80px 100px 80px;
		position: relative;
		z-index: 1;

		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		border: 30px solid $color-white;

		color: $color-white;
		text-align: center;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6
		{
			margin: 0 0 1rem 0;
			color: $color-white;
		}
	}

		.cta-panel:before
		{
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;

			background-color: rgba(0,0,0,0.7);
		}

		.cta-panel-about:before
		{
			background-color: transparentize($color-blue-dark, 0.4);
		}

		.cta-panel-about:after
		{
			content: ' ';
			position: absolute;
			bottom: 0;
			right: 0;
			width: 400px;
			height: 200px;
			z-index: -1;

			background-image: url(../images/icon-waves-white.svg);
			background-repeat: no-repeat;
			background-size: cover;
		}

	.homepage-sections
	{
		margin: 0;
		padding: 0;
		padding: 0;

		.col-lg-6
		{
			background-size: cover;
			min-height: 300px;
		}

		.content
		{
			padding: 10%;
		}
	}

	.feature-nav
	{
		padding: 0 50px;
		margin-top: -100px;
		z-index: 2000;
		position: relative;

		.item
		{
			display: block;
			width: 100%;
			height: 100%;
			min-height: 531px;
			padding: 20px;
			position: relative;
			z-index: 0;

			border-radius: 30px;

			.title
			{
				width: 100%;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);

				text-align: center;
				font-size: 60px;
				color: $color-white;
			}
		}

		.item:before
		{
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;

			-webkit-transition: all 150ms ease-in-out;
			-moz-transition: all 150ms ease-in-out;
			-ms-transition: all 150ms ease-in-out;
			-o-transition: all 150ms ease-in-out;
			transition: all 150ms ease-in-out;

			border-radius: 30px;
			background-color: rgba(0,0,0,0.7);
		}

		.item:hover:before
		{
			background-color: rgba(0,0,0,0.3);
		}
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.feature-nav
		{
			.item
			{
				.title
				{
					font-size: 40px;
				}
			}
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.cta-panel-about:after
		{
			display: none;
		}

		.feature-nav
		{
			margin-top: 0;

			.col-md-6
			{
				margin-top: 30px;
			}

			.item
			{
				min-height: 300px;

				.title
				{
					font-size: 40px;
				}
			}
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.cta-panel
		{
			padding: 30px;
		}

		.cta-panel-about:after
		{
			display: none;
		}

		.feature-nav
		{
			margin-top: 0;
			padding: 0 20px;

			.col-md-6
			{
				margin-top: 30px;
			}

			.item
			{
				min-height: 300px;

				.title
				{
					font-size: 40px;
				}
			}
		}
	}

	/*=========================================================================*/

	/* [Footer Template Styles] */

	.footer
	{
		width: 100%;
		padding: 60px 50px;
		position: relative;

		border-bottom: 48px solid $color-blue-light;

		background-image: url(../images/bg-footer.jpg);
		background-size: cover;
		background-repeat: no-repeat;

		color: $color-white;
		text-align: center;

		a
		{
			color: $color-white;
		}

			a:hover
			{
				color: $color-blue-light;
			}

		.btn-primary:hover
		{
			color: $color-white;
		}

		.logo img
		{
			max-width: 180px;
		}

		.social
		{
			font-size: 30px;

			color: $color-blue-light;
		}

		.nav
		{
			justify-content: center;
			margin-bottom: 20px;
		}

		.btn-secondary
		{
			padding: 0.5rem 2rem;

			background-color: transparent;
			background-image: none;
			border: 1px solid $color-white;
			border-radius: 30px;

			text-transform: uppercase;
		}
	}

	.footer:before
	{
		content: ' ';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		background-color: rgba(0,0,0,0.7);
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.footer
		{
			.nav
			{
				display: block;

			}
		}
	}

	/*=========================================================================*/

	/* [Table as row override Styles] */

	table.table-as-row > tbody > tr:before,
	table.table-as-row > tbody > tr:after {
		content: " ";
		display: table;
		clear: both;
	}

	table.table-as-row > tbody > tr {
		height: auto !important;
	}

	table.table-as-row,
	table.table-as-row > tbody,
	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
	}

	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
		margin-right: -15px;
		margin-left: -15px;
	}

	table.table-as-row > tbody > tr > td[class*=col-] {
		display: block;
		height: auto !important;
		margin-bottom: 20px;
	}

	/* col-lg */
	@media (min-width: 1200px) {
		table.table-as-row > tbody > tr > td[class*=col-lg-] {
			float: left;
		}
	}

	/* col-md */
	@media (min-width: 992px) {
		table.table-as-row > tbody > tr > td[class*=col-md-] {
			float: left;
		}
	}

	/* col-sm */
	@media (min-width: 768px) {
		table.table-as-row > tbody > tr > td[class*=col-sm-] {
			float: left;
		}
	}

	/* col-xs */
	table.table-as-row > tbody > tr > td[class*=col-xs-] {
		float: left;
	}